import React from 'react';
import { currencySymbolMap } from 'currency-symbol-map';
import SelectFilter from './SelectFilter';

const currencyOptions = Object.entries(currencySymbolMap).map(([ key, value ]) => ({
  id: key,
  label: `${key} (${value})`,
}));

const CurrencyFilter = ({
  controlId,
  label,
  selected,
  onChange,
}) => {
  return (
    <SelectFilter
      filterOpts
      controlId={controlId}
      label={label}
      options={currencyOptions}
      selected={selected || ''}
      onChange={onChange}
    />
  )
}

export default CurrencyFilter;
