import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
  Row,
  Card,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ContactDialog from './ContactDialog';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import handleApiResponse from '../../functions/handleApiResponse';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import capitalize from '../../functions/capitalize';
import hasRights from '../../functions/hasRights';
import fetchWithJWT from '../../functions/fetchWithJWT';
import '../../stylesheets/adminDeploymentManagement.css';
import blankFlag from '../../stylesheets/blankFlag.gif';

const getStoresDeployment = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const Confirm = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal className="filter-export-confirm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="storesDeployment.activeConfirm.warning">Warning</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t('storesDeployment.activeConfirm.text', 'You are changing the site\'s activity status.')}</p>
        <p>{t('storesDeployment.activeConfirm.continue', 'Continue?')}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          <Trans i18nKey="storesDeployment.activeConfirm.close">Close</Trans>
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          <Trans i18nKey="storesDeployment.activeConfirm.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const switchActivity = (user, updateTokens, storeId, isActive) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/activity`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ active: !isActive }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DeploymentRow = ({
  site,
  canCreateRobot,
  canCreateRacket,
  onEditContact,
  onSwitchActive,
}) => {
  const flag = `flag flag-${site.countryCode.toLowerCase()}`;
  const { t } = useTranslation();
  const isWarehouse = site.id.toString().startsWith('w');

  return (
    <tr>
      <td>
        {site.id}
      </td>
      <td>
        {capitalize(site.name)}
      </td>
      <td className="text-center">
        <div className="country-flag">
          <img src={blankFlag}  className={flag} alt={site.countryCode} />
        </div>
      </td>

      <td className="contact-cell">
        <div className="contact-wrapper">
          <div className="contact-val" onClick={onEditContact} >
            {site.contactInfo}
          </div>
          {(canCreateRobot || canCreateRacket) && (
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              title={t('storesDeployment.editContactInfo', 'Edit contact info')}
              onClick={onEditContact}
            >
              <i className="vtmn-icon_edit"></i>
            </Button>
          )}
        </div>
      </td>

      <td className="text-center">
        {site.robots.map(r => (
          <Link key={r.id} to={`/admin/store/deployment/robot/${r.id}`}>
            <Button className="tiny-button" size="sm" variant="success">
              {t('storesDeployment.activeRacket', 'Active')}
            </Button>
          </Link>
        ))}
        {(!site.robots || !site.robots.length) && (
          (canCreateRobot && !isWarehouse) ? (
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              as={Link}
              to={`/admin/store/deployment/robot/new?store=${site.id}`}
              title={t('storesDeployment.addRobot', 'Add robot')}
            >
              <i className="vtmn-icon_edit_plus"></i>
            </Button>
          ) : '-'
        )}
      </td>
      <td className="text-center">
        {site.rackets.map(r => (
          <Link key={r.id} to={`/admin/store/deployment/racket/${r.id}`}>
            <Button className="tiny-button" size="sm" variant={r.isActive ? 'success' : 'danger'}>
              {(
                r.isActive
                  ? t('storesDeployment.activeRacket', 'Active')
                  : t('storesDeployment.inactiveRacket', 'Inactive')
              )}
            </Button>
          </Link>
        ))}
        {(!site.rackets || !site.rackets.length) && (
          canCreateRacket ? (
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              as={Link}
              to={`/admin/store/deployment/racket/new?store=${site.id}`}
              title={t('storesDeployment.enableRackets', 'Enable rackets')}
            >
              <i className="vtmn-icon_edit_plus"></i>
            </Button>
          ) : '-'
        )}
      </td>
      <td className="text-center activity-filter">
        <SwitchFilter
          controlId={`activity-filter-${site.id}`}
          value={site.isActive}
          onChange={onSwitchActive}
          disabled={!canCreateRacket}
        />
      </td>
      <td className="text-center">
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('storesDeployment.editSettings', 'Edit settings')}
          as={Link}
          to={`/admin/store/deployment/settings/${site.id}`}
        >
          <i className="vtmn-icon_edit"></i>
        </Button>
      </td>
    </tr>
  )
};


const AdminDeployment = ({ user, updateTokens }) => {
  const canCreateRobot = hasRights(user, 'createRobot');
  const canCreateRacket = hasRights(user, 'createRacket');
  const hasPanel = canCreateRobot || canCreateRacket;
  const [data, setData] = useState(null);
  const [contact, setContact] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [switchedSite, setSwitchedSite] = useState(null);
  const [{
    loading,
    error,
  }, fetchStoresDeployment] = useRequest(
    getStoresDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );
  useEffect(() => {
    fetchStoresDeployment(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActivity = () => {
    const tData = [...data];
    const record = tData.find((r) => r.id === switchedSite.id);
    if (!record) {
      return;
    }
    record.isActive = !record.isActive;
    setData(tData);
    setSwitchedSite();
  };

  const [{
    loading: loadingActivity,
    error: errorActivity,
  }, fetchActivity] = useRequest(
    switchActivity, {
      onSuccess: updateActivity
    }
  );

  const changeActivity = () => {
    if (!switchedSite || !switchedSite.id) {
      return;
    }
    fetchActivity(user, updateTokens, switchedSite.id, switchedSite.isActive);
  };

  return (
    <Container className="adminDeploymentManagement">
      {hasPanel && (
        <Row>
          <Card body className="admin-top-card">
            {canCreateRobot && (
              <Button className="icon-button" as={Link} to="/admin/store/deployment/robot/new">
                <i className="vtmn-icon_edit_plus"></i>
                <Trans i18nKey="storesDeployment.addRobot">Add robot</Trans>
              </Button>
            )}
            {canCreateRacket && (
              <Button className="icon-button" as={Link} to="/admin/store/deployment/racket/new">
                <i className="vtmn-icon_edit_plus"></i>
                <Trans i18nKey="storesDeployment.enableRackets">Enable rackets</Trans>
              </Button>
            )}
          </Card>
        </Row>
      )}

      {loading && <Loader />}
      {!loadingActivity && errorActivity && <ErrorMessage error={errorActivity} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <Table hover>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <Trans i18nKey="storesDeployment.site">Site</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storesDeployment.description">Description</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesDeployment.country">Country</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storesDeployment.contacts">Contacts</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesDeployment.robots">Robots</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesDeployment.rackets">Rackets</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesDeployment.activityState">Activity</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesDeployment.settings">Settings</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(site => (
              <DeploymentRow
                key={site.id}
                site={site}
                canCreateRobot={canCreateRobot}
                canCreateRacket={canCreateRacket}
                onEditContact={() => setContact({
                  siteId: site.id,
                  contactId: site.contactId,
                  contactInfo: site.contactInfo,
                })}
                onSwitchActive={() => {
                  setShowConfirm(true);
                  setSwitchedSite(site);
                }}
              />
            ))}
          </tbody>
        </Table>
      )}
      <ContactDialog
        user={user}
        updateTokens={updateTokens}
        siteId={contact && contact.siteId}
        contactId={contact && contact.contactId}
        contactInfo={contact && contact.contactInfo}
        open={!!contact}
        onClose={() => setContact(null)}
        onSaved={() => {
          setContact(null);
          fetchStoresDeployment(user, updateTokens);
        }}
      />
      <Confirm
        show={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          setShowConfirm(false);
          changeActivity();
        }}
      />
    </Container>
  );
};

export default AdminDeployment;
