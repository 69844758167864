import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'
import { withTranslation, useTranslation, Trans } from 'react-i18next'
import ErrorMessage from './errorMessage'
import useRequest from '../functions/useRequest'
import fetchWithJWT from '../functions/fetchWithJWT'
import handleApiResponse from '../functions/handleApiResponse'
import '../stylesheets/navbar.css'
import getPilotSites from '../functions/getPilotSites'
import getStoreFeatures from '../functions/getStoreFeatures'
import getStoreNotifications from '../functions/getStoreNotifications'
import SitesMenuItems from './widgets/SitesMenuItem'
import blankFlag from '../stylesheets/blankFlag.gif'

const baseUrl = process.env.REACT_APP_base_URL

const LOCALES_MAP = {
  en: {
    code: 'en-US',
    flag: 'flag-gb',
    alt: 'English',
  },
  bg: {
    code: 'bg-BG',
    flag: 'flag-bg',
    alt: 'Bulgarian',
  },
  zh: {
    code: 'zh-ZH',
    flag: 'flag-cn',
    alt: 'Chinese',
  },
  cz: {
    code: 'cz-CZ',
    flag: 'flag-cz',
    alt: 'Czech',
  },
  nl: {
    code: 'nl-NL',
    flag: 'flag-nl',
    alt: 'Dutch',
  },
  fr: {
    code: 'fr-FR',
    flag: 'flag-fr',
    alt: 'French',
  },
  de: {
    code: 'de-DE',
    flag: 'flag-de',
    alt: 'German',
  },
  it: {
    code: 'it-IT',
    flag: 'flag-it',
    alt: 'Italian',
  },
  pl: {
    code: 'pl-PL',
    flag: 'flag-pl',
    alt: 'Polish',
  },
  pt: {
    code: 'pt-PT',
    flag: 'flag-pt',
    alt: 'Portuguese',
  },
  br: {
    code: 'br',
    flag: 'flag-br',
    alt: 'Brazilian Portuguese',
  },
  ro: {
    code: 'ro-RO',
    flag: 'flag-ro',
    alt: 'Romanian',
  },
  ru: {
    code: 'ru-RU',
    flag: 'flag-ru',
    alt: 'Russian',
  },
  es: {
    code: 'es-ES',
    flag: 'flag-es',
    alt: 'Spanish',
  },
  tr: {
    code: 'tr-TR',
    flag: 'flag-tr',
    alt: 'Turkish',
  },
}

const LangSelect = () => {
  const { i18n } = useTranslation()
  const currentLocale = LOCALES_MAP[i18n.resolvedLanguage]

  return (
    <Nav.Item
      as={Dropdown}
      navbar
      className="dropdown-lang-select clickableText"
    >
      <Dropdown.Toggle as="div">
        <img
          src={blankFlag}
          className={`flag ${currentLocale.flag}`}
          alt={currentLocale.alt}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-right"
        aria-labelledby="navbaDropdownReporting"
      >
        {Object.values(LOCALES_MAP).map((locale) => (
          <Dropdown.Item
            key={locale.code}
            onClick={() => i18n.changeLanguage(locale.code)}
            className="clickableText"
          >
            <img
              src={blankFlag}
              className={`flag ${locale.flag}`}
              alt={locale.alt}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Nav.Item>
  )
}

const setRole = (user, updateTokens, role) => {
  const { token, refreshToken, tokenExpireDate } = user
  const url = `${baseUrl}/api/auth/role`
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ role }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}
const roles = [
  'superAdmin',
  'countryLeader',
  'supportEngineer',
  'efficiencyManager',
  'regionLeader',
  'warehouseManager',
  'warehouseCrew',
  'regionalManager',
  'storeOperationManager',
  'basic',
];
const RoleSelect = ({ user, updateTokens }) => {
  const [{
    loading,
    error,
  }, changeRole] = useRequest(setRole, {
    onSuccess: () => window.location.replace("/"),
  });

  return (
    <>
      {!loading && error && <ErrorMessage error={error} />}
      {!error && (
        <NavDropdown
          className="dropdown-site-select"
          title={user.role || 'superAdmin'}
        >
          {roles.map((role, i) => (
            <div
              key={i}
              className="user-role-select"
              onClick={() => changeRole(user, updateTokens, role)}
            >
              <NavDropdown.Item className="site" as="div">
                {role}
              </NavDropdown.Item>
            </div>
          ))}
        </NavDropdown>
      )}
    </>
  )
}

class AppNavbar extends Component {
  state = {
    sites: [],
    storeFeatures: {
      hasRobot: false,
      hasGate: false,
      hasEfficiency: false,
    },
    storeNotifications: [],
  }
  _isMounted = false

  constructor(props) {
    super(props)
    this.siteFilterRef = React.createRef()
  }

  componentDidMount() {
    const { match } = this.props
    const { store } = match.params
    this.loadSites()
    if (store) {
      this.loadStore(store)
    }
    this._isMounted = true
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch } = prevProps
    const { store: prevStore } = prevMatch.params
    const { match } = this.props
    const { store } = match.params
    if (store && store !== prevStore) {
      this.loadStore(store)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  loadSites = () => {
    const { user, updateTokens } = this.props
    getPilotSites(user, updateTokens)
      .then((sites) => {
        if (this._isMounted) {
          this.setState({ sites: sites || [] })
        }
      })
      .catch((error) => {
        console.warn(`Error while searching for sites`, error)
      })
  }

  loadStore = (store) => {
    const { user, updateTokens } = this.props
    getStoreFeatures(store, user, updateTokens)
      .then((storeFeatures) => {
        if (this._isMounted) {
          this.setState({ storeFeatures })
        }
      })
      .catch((error) => {
        console.warn(`Error while loading store features`, error)
      })
    getStoreNotifications(store, user, updateTokens)
      .then((storeNotifications) => {
        if (this._isMounted) {
          this.setState({ storeNotifications })
        }
      })
      .catch((error) => {
        console.warn(`Error while loading store notifications`, error)
      })
  }

  getSelectedSiteTitle = () => {
    const { store, warehouse } = this.props.match.params
    let selectedSiteTitle = 'Not selected'
    if (store) {
      const myStore = this.state.sites.filter((s) => s.id.toString() === store)
      const storeName = (myStore[0] && myStore[0].name) || 'Unknown'
      selectedSiteTitle = `${store} - ${storeName}`
    }
    if (warehouse) {
      const myWarehouse = this.state.sites.filter(
        (s) => s.id.toString() === warehouse,
      )
      const warehouseName = (myWarehouse[0] && myWarehouse[0].name) || 'Unknown'
      selectedSiteTitle = `${warehouse} - ${warehouseName}`
    }
    return selectedSiteTitle
  }

  openDocumentation = (language) => {
    const links = [
      {
        language: 'EN',
        link:
          'https://docs.google.com/presentation/d/1qhdDH8iVUVPeQB8lpVWqmIGBon1IE07EnV3u02tYvlg',
      },
    ]
    window.open(links.find((link) => link.language === language).link)
  }

  hasNotification = (code) => {
    const { storeNotifications } = this.state
    const has = !!storeNotifications.find(
      (notification) => notification.code === code,
    )
    return has
  }

  getNotificationTooltip = (code) => {
    const { storeNotifications } = this.state
    const { t } = this.props
    const notification = storeNotifications.find(
      (notification) => notification.code === code,
    )
    let tooltip = ''
    if (notification) {
      const { severity } = notification
      if (code === 'GATE_PERCENT_OF_READ') {
        if (severity === 'danger') {
          tooltip = t(
            'monitoring.tooltips.gateLess50',
            'last read less then 50% of expected',
          )
        } else {
          tooltip = t(
            'monitoring.tooltips.gateLess90',
            'last read less then 90% of expected',
          )
        }
      } else if (code === 'ROBOT_PERCENT_OF_MAX') {
        if (severity === 'danger') {
          tooltip = t(
            'monitoring.tooltips.robotLess50',
            'last read less then 50% of max',
          )
        } else {
          tooltip = t(
            'monitoring.tooltips.robotLess90',
            'last read less then 90% of max',
          )
        }
      }
    }
    return tooltip
  }

  getMonitoringNotificationCount = () => {
    const { storeNotifications } = this.state
    const monitoringNotifications = [
      'GATE_PERCENT_OF_READ',
      'ROBOT_PERCENT_OF_MAX',
    ]
    const count = storeNotifications.filter(
      (notification) =>
        monitoringNotifications.indexOf(notification.code) !== -1,
    ).length
    return count
  }

  renderStoreNav() {
    const isStoreSelected = !!this.props.match.params.store
    const isWarehouseSelected = !!this.props.match.params.warehouse
    const { t, user, updateTokens } = this.props
    const { storeFeatures, sites } = this.state
    const { hasRobot, hasGate, hasRacket, hasEfficiency } = storeFeatures
    const monitoringNotificationCount = this.getMonitoringNotificationCount()
    const hasRobotNotification = this.hasNotification('ROBOT_PERCENT_OF_MAX')
    const robotNotificationTooltip = this.getNotificationTooltip(
      'ROBOT_PERCENT_OF_MAX',
    )
    if (isStoreSelected) {
      return (
        <Nav className="mr-auto mt-2 mt-lg-0">
          {hasEfficiency && (
            <NavDropdown
              title={t('navbar.efficiency', 'Efficiency')}
            >
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/reporting/efficiency'
                }
              >
                <Trans i18nKey="navbar.control.storeEfficiency">
                  Store efficiency
                </Trans>
              </NavDropdown.Item>
              {user.rights.indexOf('countryEfficiency') > -1 && (
                <NavDropdown.Item
                  as={Link}
                  to={
                    '/' +
                    this.props.match.params.country +
                    '/' +
                    this.props.match.params.store +
                    '/admin/reporting/efficiency'
                  }
                >
                  <Trans i18nKey="navbar.countryEfficiency">
                    Country efficiency
                  </Trans>
                </NavDropdown.Item>
              )}
              {user.rights.indexOf('editEfficiencyMeasurement') > -1 && (
                <NavDropdown.Item
                  as={Link}
                  to={
                    '/' +
                    this.props.match.params.country +
                    '/' +
                    this.props.match.params.store +
                    '/efficiency/measurements'
                  }
                >
                  <Trans i18nKey="efficiencyAdmin.navTitle">
                    Records Edit
                  </Trans>
                </NavDropdown.Item>
              )}
              {user.rights.indexOf('editEfficiencyMeasurement') > -1 && (
                <NavDropdown.Item
                  as={Link}
                  to={`/${this.props.match.params.country}/${this.props.match.params.store}/reporting/efficiency_monitoring`}
                >
                  <Trans i18nKey="navbar.efficiencyDashboard">
                    Metabase dashboard
                  </Trans>
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {(hasRobot || hasGate || hasRacket) && (
            <NavDropdown title={t('navbar.rfidDelivery', 'RFID Delivery control')}>
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/real_time/store_reception'
                }
              >
                <Trans i18nKey="navbar.realTime.storeDelivery">
                  Control receptions
                </Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/reporting/discrepancies'
                }
              >
                <Trans i18nKey="navbar.reporting.discrepancies">
                  Discrepancies
                </Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/monitoring/gate'
                }
                className={hasGate ? '' : 'font-weight-lighter'}
              >
                <Trans i18nKey="navbar.monitoring.rfidMonitoring">
                  Monitoring delivery control
                </Trans>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`/${this.props.match.params.country}/${this.props.match.params.store}/reporting/usage_monitoring`}
              >
                <Trans i18nKey="navbar.reporting.usageMonitoring">
                  Metabase dashboard
                </Trans>
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {hasRobot && (
            <NavDropdown
              title={
                <>
                  <Trans i18nKey="navbar.rfidRobot">RFID Robot</Trans>
                  {!!monitoringNotificationCount && (
                    <div className="notification-badge">
                      {monitoringNotificationCount}
                    </div>
                  )}
                </>
              }
            >
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/monitoring/robot'
                }
                className={hasRobot ? '' : 'font-weight-lighter'}
              >
                <Trans i18nKey="navbar.monitoring.robot">Robot inventories</Trans>
                {hasRobotNotification && (
                  <i
                    className="notification icon vtmn-icon_notif_information"
                    title={robotNotificationTooltip}
                  ></i>
                )}
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {(hasRobot || hasGate || hasRacket) && (
            <NavDropdown title={t('navbar.rfidReturns', 'RFID returns control')}>
              <NavDropdown.Item
                as={Link}
                to={
                  '/' +
                  this.props.match.params.country +
                  '/' +
                  this.props.match.params.store +
                  '/store_returns'
                }
              >
                <Trans i18nKey="navbar.realTime.storeReturns">
                  Control returns
                </Trans>
              </NavDropdown.Item>
            </NavDropdown>
          )}
          <SitesMenuItems
            sites={sites}
            user={user}
            updateTokens={updateTokens}
          />

          <NavDropdown title={t('navbar.help.toggle', 'Help')}>
            <NavDropdown.Item onClick={() => this.openDocumentation('EN')}>
              <Trans i18nKey="navbar.help.english">User guide in English</Trans>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )
    }

    if (isWarehouseSelected) {
      return (
        <Nav className="mr-auto mt-2 mt-lg-0">
          <NavDropdown
            title={t('navbar.rfidDeliveryControl', 'RFID Delivery control')}
          >
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/contenants/declare`}
            >
              <Trans i18nKey="navbar.warehouse.contenants.declareToControl">
                Declare to control
              </Trans>
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/contenants/control`}
            >
              <Trans i18nKey="navbar.warehouse.contenants.control">
                Control
              </Trans>
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/contenants/report`}
            >
              <Trans i18nKey="navbar.warehouse.contenants.report">Picking control results</Trans>
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/reporting/wrongStore`}
            >
              <Trans i18nKey="navbar.warehouse.contenants.wrongStore">
                Sent to wrong store
              </Trans>
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/reporting/discrepancies`}
            >
              <Trans i18nKey="navbar.warehouse.contenants.discrepancies">
                Discrepancies
              </Trans>
            </NavDropdown.Item>
            {user.rights.indexOf('warehouseAdmin') > -1 && (
              <NavDropdown.Item
                as={Link}
                to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/contenants/admin`}
              >
                <Trans i18nKey="navbar.warehouse.contenants.warehouseAdmin">
                  Warehouse admin
                </Trans>
              </NavDropdown.Item>
            )}
          </NavDropdown>
          <NavDropdown title={t('navbar.rfidReturns', 'RFID returns control')}>
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/store_returns`}
            >
              <Trans i18nKey="navbar.warehouse.reporting.storeReturns">
                Store returns
              </Trans>
            </NavDropdown.Item>
          </NavDropdown>


          <NavDropdown
            title={t('navbar.warehouse.monitoring.toggle', 'Monitoring')}
          >
            <NavDropdown.Item
              as={Link}
              to={`/${this.props.match.params.country}/warehouse/${this.props.match.params.warehouse}/reporting/usage_monitoring`}
            >
              <Trans i18nKey="navbar.warehouse.monitoring.usageMonitoring">
                Usage monitoring dashboard
              </Trans>
            </NavDropdown.Item>
          </NavDropdown>
          <SitesMenuItems
            sites={sites}
            user={user}
            updateTokens={updateTokens}
          />

          <NavDropdown title={t('navbar.help.toggle', 'Help')}>
            <NavDropdown.Item onClick={() => this.openDocumentation('EN')}>
              <Trans i18nKey="navbar.help.english">User guide in English</Trans>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )
    }

    return (
      <Nav className="mr-auto mt-2 mt-lg-0">
        <NavDropdown
          title={t('navbar.rfidDeliveryControl', 'RFID Delivery control')}
        >
          <NavDropdown.Item as={Link} to="/admin/store/monitoring" role="button">
            <Trans i18nKey="navbar.administrating.storeMonitoring">
              Monitoring
            </Trans>
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/store/monitoring/robots" role="button">
            <Trans i18nKey="navbar.administrating.storeMonitoringRobots">
              Monitoring robots
            </Trans>
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/admin/reporting/discrepancies">
            <Trans i18nKey="navbar.administrating.reporting.discrepancies">
              Store discrepancies
            </Trans>
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={`/admin/reporting/usage_monitoring`}>
            <Trans i18nKey="navbar.administrating.reporting.usageMonitoring">
              Usage monitoring dashboard
            </Trans>
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          title={t('navbar.efficiency', 'Efficiency')}
        >
          <NavDropdown.Item as={Link} to={`/admin/reporting/efficiency`}>
            <Trans i18nKey="efficiencyDashboard.navTitle">
              Country efficiency
            </Trans>
          </NavDropdown.Item>
          {user.rights.indexOf('adminEfficiency') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/efficiency">
              <Trans i18nKey="efficiencyAdmin.navTitle">
                Records Edit
              </Trans>
            </NavDropdown.Item>
          )}
          {user.rights.indexOf('adminEfficiency') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/efficiency/deployment">
              <Trans i18nKey="navbar.administrating.efficiencyManagement">
                Stores deployment
              </Trans>
            </NavDropdown.Item>
          )}
          {user.rights.indexOf('adminEfficiency') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/efficiency/country/deployment">
              <Trans i18nKey="navbar.administrating.countryEfficiency">
                Country settings
              </Trans>
            </NavDropdown.Item>
          )}
          {user.rights.indexOf('adminEfficiency') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/efficiency/efficiency_monitoring">
              <Trans i18nKey="navbar.efficiencyDashboard">
                Metabase dashboard
              </Trans>
            </NavDropdown.Item>
          )}
        </NavDropdown>
        <NavDropdown
          title={t('navbar.administrating.admin', 'Admin')}
        >
          {user.rights.indexOf('showPreloads') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/preload/state">
              <Trans i18nKey="navbar.administrating.reporting.preloadStates">
                Preload states
              </Trans>
            </NavDropdown.Item>
          )}
          <NavDropdown.Item as={Link} to="/admin/store/deployment" role="button">
            <Trans i18nKey="navbar.administrating.deploymentManagement">
              Deployment
            </Trans>
          </NavDropdown.Item>
          {(user.rights.indexOf('userManagement') > -1 ||
            user.rights.indexOf('userManagementCountry') > -1) && (
              <NavDropdown.Item as={Link} to="/admin/users" role="button">
                <Trans i18nKey="navbar.administrating.usersManagement">
                  Users
                </Trans>
              </NavDropdown.Item>
            )}
          {user.rights.indexOf('storeGroupManagement') > -1 && (
            <NavDropdown.Item as={Link} to="/admin/store_groups" role="button">
              <Trans i18nKey="navbar.administrating.groupOfStores">
                Group of stores
              </Trans>
            </NavDropdown.Item>
          )}
        </NavDropdown>
        <NavDropdown title={t('navbar.help.toggle', 'Help')}>
          <NavDropdown.Item onClick={() => this.openDocumentation('EN')}>
            <Trans i18nKey="navbar.help.english">User guide in English</Trans>
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    )
  }

  render() {
    const { user, updateTokens } = this.props
    const { sites } = this.state
    const hasSites = sites && sites.length > 0
    const selectedSiteTitle = this.getSelectedSiteTitle()
    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Toggle aria-controls="navbar" label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Brand as={Link} to="/">
          End2End-RFID
          <div className="mobile-site-title d-lg-none">
            {hasSites && selectedSiteTitle}
          </div>
        </Navbar.Brand>
        <Navbar.Collapse id="navbar">
          {this.renderStoreNav()}
          <Nav className="navbar-nav-right mt-2 mt-lg-0">
            {user.rights.indexOf('changeRole') > -1 && (
              <RoleSelect user={user} updateTokens={updateTokens} />
            )}
            <LangSelect />
            <Nav.Item as="li">
              <Nav.Link as="div" role="button" onClick={this.props.logOut}>
                <i className="vtmn-icon_power clickableText" alt="login" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default withTranslation()(AppNavbar)
