import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import fetchWithJWT from '../../functions/fetchWithJWT'
import handleApiResponse from '../../functions/handleApiResponse'
import getQueryString from '../../functions/getQueryString'
import useRequest from '../../functions/useRequest'
import Loader from '../loader'
import ErrorMessage from '../errorMessage'
import { CountryModeFilters, StoreModeFilters, getDefaultFilters, parseFilters } from './filters'
import EfficiencyView from './EfficiencyView'
import BreakdownTable from './BreakdownTable'
import StoreEfficiencyChart from './StoreEfficiencyChart'
import OutliersWarning from './OutliersWarning'
import '../../stylesheets/efficiencyDashboard.css';

const getDashboard = (user, updateTokens, filters, withTopPerformer) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let queryString = getQueryString({ filters });
  if (withTopPerformer) {
    queryString += '&with_top_performer=true';
  }
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const EfficiencyDashboard = ({ user, updateTokens, mode, title }) => {
  const { t } = useTranslation();
  const { store, country } = useParams();
  const isStoreView = mode === 'store';
  const isCountryView = mode === 'country' || mode === 'admin';

  const [
    { loading, error, data: dashboard },
    fetchDashboard,
  ] = useRequest(getDashboard);
  const [filters, setFilters] = useState(getDefaultFilters(mode));

  const noData = !loading && !error && dashboard && !dashboard.delivery_count;
  const isReady = !loading && !error && !noData && dashboard;

  useEffect(() => {
    const parsedFilters = parseFilters(filters, mode, store);
    fetchDashboard(user, updateTokens, parsedFilters, isStoreView);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, mode, filters]);

  useEffect(() => {
    if (!country) {
      return;
    }
    setFilters((prevFilters) => ({ ...prevFilters, country_id: country }));
  }, [country]);

  return (
    <div className="container-fluid efficiency-dashboard">
      <h1>{title}</h1>
      {isCountryView && (
        <CountryModeFilters
          filters={filters}
          setFilters={setFilters}
          user={user}
          updateTokens={updateTokens}
        />
      )}
      {isStoreView && (
        <StoreModeFilters
          filters={filters}
          setFilters={setFilters}
          user={user}
          updateTokens={updateTokens}
        />
      )}

      {loading && <Loader />}
      {!loading && error && <ErrorMessage error={error} />}
      {noData && (
        <div className="p-3 text-gray">{t('efficiencyDashboard.noData', 'No KPI data to display')}</div>
      )}
      {isReady && (
        <EfficiencyView dashboard={dashboard} mode={mode} />
      )}
      <OutliersWarning
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        storeView={isStoreView}
        cleanRecords={dashboard ? dashboard.delivery_count : 0}
        mode={mode}
        store={store}
        country={country}
      />
      {isCountryView && !noData && (
        <>
          <br/>
          <h2>
            <Trans i18nKey="efficiencyDashboard.breakdown.title">Breakdown by store</Trans>
          </h2>
          <BreakdownTable
            user={user}
            updateTokens={updateTokens}
            filters={filters}
            showInvestment={dashboard ? !!dashboard.show_investment : false}
          />
        </>
      )}
      {isStoreView && isReady && (
        <StoreEfficiencyChart
          user={user}
          updateTokens={updateTokens}
          filters={filters}
        />
      )}
    </div>
  );
}

export default EfficiencyDashboard;
