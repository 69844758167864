import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { withTranslation, Trans } from 'react-i18next'

import getPilotSites from "./functions/getPilotSites";
import './theme.scss'
import './App.css'
import Login from './components/login'
import Loader from './components/loader'
import Navbar from './components/navbar'
import LiveStoreReceptionV2 from './components/liveStoreReceptionV2'
import ChooseStore from './components/chooseStore'
import ReportingStoreReception from './components/reportingStoreReception'
import GoogleOAuth from './components/googleOAuth'
import MonitoringRobot from './components/monitoringRobot'
import MonitoringGate from './components/monitoringGate'
import AdminStoresMonitoring from './components/adminStoresMonitoring'
import AdminDeployment from './components/adminDeployment'
import AdminDeploymentRobot from './components/adminDeploymentRobot'
import AdminDeploymentRacket from './components/adminDeploymentRacket'
import AdminMonitoringRobots from './components/adminMonitoringRobots'
import AdminPreloadState from './components/adminPreloadState'
import Returns from './components/returns'
import WarehouseContenantsDeclareToControl from './components/warehouse/contenants/declareToControl'
import WarehouseContenantsControl from './components/warehouse/contenants/control'
import WarehouseContenantsReport from './components/warehouse/contenants/report'
import WarehouseReportingDiscrepancies from './components/warehouse/reporting/discrepancies'
import WarehouseReportingWrongStore from './components/warehouse/reporting/wrongStore'
import handleApiResponse from './functions/handleApiResponse'
import MetabaseDashboard from './components/widgets/metabaseDashboard'
import fetchWithJWT, { getAuthHeaders } from './functions/fetchWithJWT'
import EfficiencyDashboard from './components/efficiencyDashboard'
import EfficiencyAdmin from './components/efficiencyAdmin'
import UsersAdmin from './components/usersAdmin'
import UserSettings from './components/userSettings'
import EfficiencyDeployment from './components/efficiencyDeployment'
import EfficiencySettings from './components/efficiencySettings'
import EfficiencyCountryDeployment from './components/efficiencyCountryDeployment'
import EfficiencyCountrySettings from './components/efficiencyCountrySettings'
import StoreGroups from './components/storeGroups'
import StoreGroupsSettings from './components/storeGroupsSettings'
import AdminDeploymentSettings from './components/adminDeploymentSettings'
import WarehouseAdmin from './components/warehouse/contenants/admin'

const openId_URL = process.env.REACT_APP_openId_URL
const redirect_uri = process.env.REACT_APP_fedID_redirect_URI
const baseUrl = process.env.REACT_APP_base_URL
const defaultHeaders = {
  'Content-Type': 'application/json',
}

const getRights = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user
  const url = `${baseUrl}/api/auth/rights`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const getCurrentRole = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user
  const url = `${baseUrl}/api/auth/role`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const logout = ({ authHeaders, accessToken, refreshToken }) => {
  const url = `${baseUrl}/api/auth/logout`
  return fetch(url, {
    method: 'POST',
    headers: {
      ...authHeaders,
      ...defaultHeaders,
    },
    body: JSON.stringify({
      access_token: accessToken,
      refresh_token: refreshToken,
    }),
  }).then(handleApiResponse)
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null,
      refreshToken: null,
      tokenExpireDate: null,
      givenName: null,
      avatar: null,
      email: null,
      countryCode: null,
      isLoggedIn: false,
      loading: false,
      fedIDError: false,
      errorBlockedUser: false,
      noSitesUser: false,
      blockedUsersList: process.env.REACT_APP_blockedUsersList.split(' '), //USER PASSWORD COMPROMISED
      rights: [],
    }
  }

  componentDidMount() {
    const url = window.location.href
    const iAmRedirectedFromFedID =
      (url.startsWith(redirect_uri + '?code=') ||
        url.startsWith(redirect_uri + '/?code=')) &&
      url.split('&state=').length > 1 &&
      url.split('googleOAuth').length === 1
    const wrongAuthenticationFromFedID = url.split('error=').length > 1
    const iAmRedirectedFromGoogleOAuth = url.split('googleOAuth').length > 1

    if (iAmRedirectedFromFedID) {
      localStorage.removeItem('reauthenticateRequired')
      const code = url.split('code=')[1].split('&')[0]
      const state = url.split('state=')[1].split('&')[0]
      this.setState({ loading: true })
      this.getAuthTokens(code, state)
        .then(this.getUserInfos)
        .then(() => this.setState({ loading: false }))
    } else if (wrongAuthenticationFromFedID) {
      this.setState({ fedIDError: true })
    } else if (iAmRedirectedFromGoogleOAuth) {
      this.setState({ iAmRedirectedFromGoogleOAuth: true })
    }
  }

  errorFromFedID = () => {
    return (
      <div className="container-fluid">
        <p>
          <Trans i18nKey="login.fedIDDenied">
            Access denied: you were not authenticated by Decathlon FedID
          </Trans>
        </p>
        <p>
          <Trans i18nKey="login.retry.please">Please</Trans>{' '}
          <a href={redirect_uri}>
            <Trans i18nKey="login.retry.try">try again</Trans>
          </a>
        </p>
      </div>
    )
  }

  errorBlockedUser = () => {
    return (
      <div className="container-fluid">
        <p>
          <Trans i18nKey="login.blocked">
            Access denied: you were blocked. Please contact system administrator
          </Trans>
        </p>
        <p>
          <Trans i18nKey="login.retry.please">Please</Trans>{' '}
          <a href={redirect_uri}>
            <Trans i18nKey="login.retry.try">try again</Trans>
          </a>
        </p>
      </div>
    )
  }

  noSitesUser = () => {
    return (
      <div className="container-fluid">
        <p>
          <Trans i18nKey="login.noSites">
            Access denied: There are no stores associated with your profile. Please contact system administrator
          </Trans>
        </p>
        <p>
          <Trans i18nKey="login.retry.please">Please</Trans>{' '}
          <a href={redirect_uri}>
            <Trans i18nKey="login.retry.try">try again</Trans>
          </a>
        </p>
      </div>
    )
  }

  getAuthTokens = (code, state) => {
    this.setState({ fedIDError: false })
    const codeVerifier = sessionStorage.getItem(`login-code-verifier-${state}`)
    return fetch(`${baseUrl}/api/auth/token`, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({
        code: code,
        redirect_uri: redirect_uri,
        code_verifier: codeVerifier,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          return response.text().then((e) => {
            throw new Error(e)
          })
        } else {
          return response.json()
        }
      })
      .then((tokens) => {
        localStorage.removeItem('reauthenticateRequired')
        const token = tokens.access_token
        const refreshToken = tokens.refresh_token
        const tokenExpiresIn = tokens.expires_in
        this.updateTokens({ token, refreshToken, tokenExpiresIn })
        return tokens
      })
      .catch((error) => {
        this.setState({ fedIDError: true })
        console.warn(`Error while retrieving infos for user : ${error}`)
      })
  }

  updateTokens = ({ token, refreshToken, tokenExpiresIn }) => {
    const now = Date.now() / 1000
    const tokenExpireDate = now + tokenExpiresIn
    return new Promise((resolve) =>
      this.setState({ token, refreshToken, tokenExpireDate }, resolve),
    )
  }

  getUserInfos = (tokens) => {
    if (!tokens) {
      return
    }
    const token = tokens.access_token
    const myBearer = {
      Authorization: `Bearer ${token}`,
    }
    return fetch(`${openId_URL}`, {
      method: 'POST',
      headers: myBearer,
    })
      .then((result) => {
        //if I don't receive your infos profile, you are not authorized to the app
        if (result.status === 200) {
          return result
        } else {
          window.location.replace(redirect_uri)
        }
      })
      .then((result) => result.json())
      .then((result) => {
        if (this.state.blockedUsersList.includes(result.uid)) {
          console.warn(`User ${result.uid} is blocked`)
          this.setState({
            errorBlockedUser: true,
          })
        } else {
          this.setState({
            countryCode: result.c,
            givenName: result.givenName,
            email: result.mail,
          })
        }
      })
      .then(() => getRights(this.state, this.updateTokens))
      .then((rights) => {
        this.setState({ rights })
        if (rights.indexOf('changeRole') > -1) {
          getCurrentRole(this.state, this.updateTokens)
            .then((role) => {
              this.setState({ role })
            });
        }
      })
      .then(() => getPilotSites(this.state, this.updateTokens))
      .then((sites) => {
        if (!sites.length) {
          this.setState({
            noSitesUser: true,
          })
        }
        this.setState({ isLoggedIn: true });
      })
      .catch((error) => {
        console.warn(`Error while retrieving infos for user : ${error}`)
      })
  }

  logOut = async () => {
    const { token, refreshToken, tokenExpireDate } = this.state
    let updatedToken = token
    let updatedRefreshToken = refreshToken
    try {
      const authHeaders = await getAuthHeaders({
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens: ({ token, refreshToken, tokenExpiresIn }) => {
          updatedToken = token
          updatedRefreshToken = refreshToken
          this.updateTokens({ token, refreshToken, tokenExpiresIn })
        },
      })
      await logout({
        authHeaders,
        accessToken: updatedToken,
        refreshToken: updatedRefreshToken,
      })
      localStorage.reauthenticateRequired = 'true'
      this.setState({
        isLoggedIn: false,
        givenName: null,
        countryCode: null,
        avatar: null,
        email: null,
        token: null,
        refreshToken: null,
        tokenExpireDate: null,
      })
    } catch (e) {
      if (e.status === 401) {
        window.location.reload()
      } else {
        console.warn(`Error during logout : ${e}`)
      }
    }
  }

  render() {
    const { t } = this.props
    const { rights } = this.state;

    if (this.state.iAmRedirectedFromGoogleOAuth) {
      return <GoogleOAuth />
    } else if (!this.state.isLoggedIn && !this.state.fedIDError) {
      if (this.state.loading) {
        return <Loader />
      } else {
        const reauthenticate = localStorage.reauthenticateRequired === 'true'
        return <Login reauthenticate={reauthenticate} />
      }
    } else if (!this.state.isLoggedIn && this.state.fedIDError) {
      return this.errorFromFedID()
    } else if (this.state.errorBlockedUser) {
      return this.errorBlockedUser()
    } else if (this.state.noSitesUser) {
      return this.noSitesUser()
    } else {
      return (
        <Router>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    {rights.length && (
                      <div className="with-navbar">
                        <ChooseStore
                          user={this.state}
                          updateTokens={this.updateTokens}
                          logOut={this.logOut}
                          {...routerProps}
                        />
                      </div>
                    )}
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store/monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminStoresMonitoring
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store/monitoring/robots"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminMonitoringRobots
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store/deployment"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminDeployment
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store/deployment/robot/:robotId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminDeploymentRobot
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store/deployment/racket/:racketId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminDeploymentRacket
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />/admin/store/deployment/settings
              <Route
                exact
                path="/admin/store/deployment/settings/:siteId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminDeploymentSettings
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/:store/real_time/store_reception"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <LiveStoreReceptionV2
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/reporting/store_reception"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <ReportingStoreReception
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/monitoring/robot"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MonitoringRobot
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/monitoring/gate"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MonitoringGate
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/reporting/efficiency"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        mode="store"
                        title={t('navbar.control.storeEfficiency', 'Store efficiency')}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/admin/reporting/efficiency"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        mode="country"
                        title={t('efficiencyDashboard.navTitle', 'Country efficiency')}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/:store/efficiency/measurements"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyAdmin
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        mode="country"
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/contenants/declare"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseContenantsDeclareToControl
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/contenants/control"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseContenantsControl
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/contenants/report"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseContenantsReport
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/reporting/discrepancies"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseReportingDiscrepancies
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/reporting/wrongStore"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseReportingWrongStore
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/admin/reporting/discrepancies"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseReportingDiscrepancies
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/:country/warehouse/:warehouse/contenants/admin"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseAdmin
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/admin/reporting/efficiency"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        mode="admin"
                        title={t('efficiencyDashboard.navTitle', 'Country efficiency')}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/efficiency/deployment/:siteId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencySettings
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/admin/efficiency/deployment"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyDeployment
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/efficiency/country/deployment/:countryId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyCountrySettings
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/admin/efficiency/country/deployment"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyCountryDeployment
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/efficiency/efficiency_monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MetabaseDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        title={t(
                          'dashboards.efficiencyMonitoring',
                          'Efficiency dashboard',
                        )}
                        metabaseResource={{ dashboard: 18 }}
                        metabaseParams={{}}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                path="/admin/efficiency"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <EfficiencyAdmin
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        mode="country"
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/users"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <UsersAdmin
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/users/:userId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <UserSettings
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/preload/state"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <AdminPreloadState
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store_groups"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <StoreGroups
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/store_groups/:groupId"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <StoreGroupsSettings
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/:store/store_returns"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <Returns
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/:store/reporting/discrepancies"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <WarehouseReportingDiscrepancies
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/warehouse/:warehouse/store_returns"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <Returns
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/admin/reporting/usage_monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MetabaseDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        title={t(
                          'dashboards.usageMonitoring',
                          'Usage monitoring dashboard',
                        )}
                        metabaseResource={{ dashboard: 5 }}
                        metabaseParams={{}}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/:store/reporting/usage_monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MetabaseDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        title={t(
                          'dashboards.usageMonitoring',
                          'Usage monitoring dashboard',
                        )}
                        metabaseResource={{ dashboard: 5 }}
                        metabaseParams={{}}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/:store/reporting/efficiency_monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MetabaseDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        title={t(
                          'dashboards.efficiencyMonitoring',
                          'Efficiency dashboard',
                        )}
                        metabaseResource={{ dashboard: 18 }}
                        metabaseParams={{}}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/warehouse/:warehouse/reporting/usage_monitoring"
                render={(routerProps) => (
                  <NeedsToBeLoggedIn
                    isLoggedIn={this.state.isLoggedIn}
                    onFailure={this.logOut}
                  >
                    <Navbar
                      user={this.state}
                      updateTokens={this.updateTokens}
                      logOut={this.logOut}
                      {...routerProps}
                    />
                    <div className="with-navbar">
                      <MetabaseDashboard
                        user={this.state}
                        updateTokens={this.updateTokens}
                        logOut={this.logOut}
                        title={t(
                          'dashboards.usageMonitoring',
                          'Usage monitoring dashboard',
                        )}
                        metabaseResource={{ dashboard: 5 }}
                        metabaseParams={{}}
                        {...routerProps}
                      />
                    </div>
                  </NeedsToBeLoggedIn>
                )}
              />
              <Route
                exact
                path="/:country/warehouse/:warehouse/*"
                render={(routeProps) => (
                  <Redirect
                    to={`/${routeProps.match.params.country}/warehouse/${routeProps.match.params.warehouse}/contenants/control`}
                  />
                )}
              />
              <Route
                exact
                path="/:country/:store/*"
                render={(routeProps) => (
                  <Redirect
                    to={`/${routeProps.match.params.country}/${routeProps.match.params.store}/real_time/store_reception`}
                  />
                )}
              />
            </Switch>
          </div>
        </Router>
      )
    }
  }
}

function NeedsToBeLoggedIn(props) {
  if (props.isLoggedIn) {
    return props.children
  } else {
    return <Redirect to="/" />
  }
}

// root App component should be wrapped with withTranslation
export default withTranslation()(App)
