import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse, { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import blankFlag from '../../stylesheets/blankFlag.gif';
import Confirm from '../widgets/Confirm';
import Filters from './Filters';
import { TableFooter } from '../widgets/DataTable';
import '../../stylesheets/users.css';

const getUsers = (user, updateTokens, { filters, pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters,
    pagination,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/users?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const deleteUser = (
  user,
  updateTokens,
  userId,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users/${userId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const UserRow = ({ user, deleteCallback }) => {
  const countries = user.countries.map((c) => (
    <div key={c.id} className="user-country">
      <img src={blankFlag}  className={`flag flag-${c.code.toLowerCase()}`} alt={c.code} />
      {c.name}
    </div>
  ))
  const { t } = useTranslation();

  return (
    <tr key={user.id}>
      <td>{user.email}</td>
      <td className="user-countries">{countries}</td>
      <td>
        {!user.countryLeader ? 'Efficiency Manager' : 'Country Efficiency Leader'}
      </td>
      <td>
        <div className="user-toolbar">
          <Link to={`/admin/users/${user.id}`}>
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              title={t('usersManagement.editUser', 'Edit user')}
            >
              <i className="vtmn-icon_edit"></i>
            </Button>
          </Link>
          <Button
            className="tiny-button"
            size="sm"
            variant="light"
            title={t('usersManagement.deleteUser', 'Delete user')}
            onClick={deleteCallback}
          >
            <i className="vtmn-icon_delete"></i>
          </Button>
        </div>
      </td>
    </tr>
  );
};

const UsersAdmin = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [deletedRow, setDeletedRow] = useState(null);
  const [{
      data,
      loading,
      error,
      filters,
      pagination,
      totals,
    },
    fetchUsers,
    setFilters,
    setPagination,
    setSort, //eslint-disable-line
    updateDataRow, //eslint-disable-line
    insertDataRow, //eslint-disable-line
    deleteDataRow,
  ] = useTableRequest(getUsers);

  useEffect(() => {
    fetchUsers(user, updateTokens, {
      filters,
      pagination,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination]);

  const updateUsersList = () => {
    deleteDataRow(deletedRow);
    setDeletedRow();
    setDeletedUser();
  };

  const [{
    loading: loadingDelete,
    error: errorDelete,
  }, fetchDelete] = useRequest(
    deleteUser, {
      onSuccess: updateUsersList
    }
  );

  const removeUser = async () => {
    if (!deletedUser) {
      return;
    }
    await fetchDelete(user, updateTokens, deletedUser);
  };

  return (
    <Container className="adminUsers">
      <Filters
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        setFilters={setFilters}
        onClear={() => setFilters({})}
      />
      {loading && <Loader />}
      {!loadingDelete && errorDelete && <ErrorMessage error={errorDelete} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <>
          <Table hover>
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <Trans i18nKey="usersManagement.email">Email</Trans>
                </th>
                <th scope="col" className="text-center">
                  <Trans i18nKey="usersManagement.countries">Countries</Trans>
                </th>
                <th scope="col" className="text-center">
                  <Trans i18nKey="usersManagement.userRole">User role</Trans>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user, i) => (
                <UserRow
                  user={user}
                  deleteCallback={() => {
                    setShowConfirm(true);
                    setDeletedRow(i);
                    setDeletedUser(user.id);
                  }}
                />
              ))}
            </tbody>
          </Table>
          <TableFooter
            pagination={pagination}
            totals={totals}
            setPagination={setPagination}
          />
        </>
      )}
      <Confirm
        title={t('usersManagement.deleteConfirm.warning', 'Warning')}
        body={t('usersManagement.deleteConfirm.text', 'Are you sure you want to delete the user?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingDelete}
        onConfirm={async () => {
          await removeUser();
          setShowConfirm(false);
        }}
      />
    </Container>
  );
};

export default UsersAdmin;
