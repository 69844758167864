import React, { useEffect, useState } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom';
import { Alert, Table, Badge } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import fetchWithJWT from '../../functions/fetchWithJWT'
import handleApiResponse from '../../functions/handleApiResponse'
import { parseFilters } from './filters'
import getQueryString from '../../functions/getQueryString'
import useRequest from '../../functions/useRequest'

const getOutliers = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;

  let queryString = getQueryString({ filters: {
    ...filters,
    records_type: 'incorrect'
  } });
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/dashboard/outliers?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const OutliersWarning = ({ user, updateTokens, filters, storeView, cleanRecords, mode, store, country }) => {
  const [show, setShow] = useState(false);
  const [outliersTotal, setOutliersTotal] = useState(0);

  const [
    { data, loading },
    fetchOutliers,
  ] = useRequest(getOutliers);

  useEffect(() => {
    const parsedFilters = parseFilters(filters, mode, store);
    fetchOutliers(user, updateTokens, parsedFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, mode, store]);

  useEffect(() => {
    if (!data || !data.length) {
      return;
    }
    setShow(true);
    setOutliersTotal(_.sumBy(data, 'total'));
  }, [data]);

  const makeRedirectUrl = (id, site_id) => {
    const qFilter = new URLSearchParams();
    qFilter.append('id', id);
    qFilter.append('site', site_id);
    qFilter.append('type', 'incorrect');
    if (filters) {
      const parsedFilters = parseFilters(filters, mode, store);
      if (parsedFilters.unloading_start_date_from) {
        qFilter.append('date_from', parsedFilters.unloading_start_date_from);
      }
      if (parsedFilters.unloading_start_date_to) {
        qFilter.append('date_to', parsedFilters.unloading_start_date_to);
      }
    }

    let url = `/${country}/${store}/efficiency/measurements?${qFilter}`;
    if (mode === 'admin') {
      url = `/admin/efficiency?${qFilter}`;
    }
    return url;
  }

  return (
    <>
    {show && data && !!data.length && !loading && (
      <Alert variant="primary" className="noRobotRun-message" onClose={() => setShow(!show)} dismissible>
        <Trans
          i18nKey="efficiencyAdmin.outliersWarning"
          defaults=""
          values={{
            total: outliersTotal + cleanRecords,
            outliers: outliersTotal
          }}
        >
          For those calculations, {outliersTotal} out of {outliersTotal + cleanRecords} records were not used as they appear to have extreme values. You can review them on the Records edit page.
        </Trans>
        <br/>
        <Trans i18nKey="efficiencyAdmin.outliersWarningEnding">
          Here is the list:
        </Trans>
        <br/><br/>
        <Table className="service-table">
          <thead>
            <tr>
              {!storeView && (
                <th scope="col">
                  <Trans i18nKey="efficiencyAdmin.columns.store">Store</Trans>
                </th>
              )}
              <th scope="col">
                <Trans i18nKey="efficiencyAdmin.columns.deliveries">Deliveries</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ site_id, outliers }, i) => (
              <tr className={i%2 === 0 && "odd"}>
                {!storeView && <td>{site_id}</td>}
                <td>
                  {outliers.map(({id, deliveries}) => (
                    <Link key={id} to={makeRedirectUrl(id, site_id)}>
                      <Badge
                        pill
                        variant="primary"
                        className="deliveries-badge"
                      >
                        {deliveries}
                      </Badge>
                    </Link>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Alert>
    )}
    </>
  );
}

export default OutliersWarning;
