import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import StoreSelect from '../widgets/StoreSelect';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import DurationEdit from '../widgets/form/DurationEdit';
import CurrencyFilter from '../widgets/filters/CurrencyFilter';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import '../../stylesheets/settingsForm.css';

const getCountrySettings = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let queryString = getQueryString({ filters });
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/country/settings?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SettingsForm = ({
  user,
  updateTokens,
  settings,
  setSettings,
  isNew,
}) => {
  const [countrySettings, setCountrySettings] = useState({});

  const [{
    loading,
    error,
  }, fetchCountrySettings] = useRequest(
    getCountrySettings, {
      onSuccess: setCountrySettings,
      onError: () => setCountrySettings({}),
    }
  );

  useEffect(() => {
    if(!settings.id) {
      return;
    }
    fetchCountrySettings(user, updateTokens, { site_id: settings.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.id]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Form>
          <StoreSelect
            user={user}
            updateTokens={updateTokens}
            selected={settings.id}
            onChange={(siteId) => setSettings({ ...settings, id: siteId })}
            disabled={!!settings.id && !isNew}
            filter={{ withoutSettings: isNew ? true : undefined }}
          />
          <Form.Group as={Row} controlId="store-currency">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.currency">
                Currency
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8" className="currency-select">
              <CurrencyFilter
                selected={settings.storeCurrency || countrySettings.currency || ''}
                onChange={(value) => setSettings({ ...settings, storeCurrency: value || null })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="store-cost-per-hour">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.costPerHour">
                Cost per hour
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8">
              <Form.Control
                step={0.01}
                min={0}
                max={1000000}
                type="number"
                value={settings.storeCostPerHour || countrySettings.cost_per_hour || ''}
                onChange={(event) => setSettings({ ...settings, storeCostPerHour: event.target.value || null })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="unloading_teammates">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.unloadingTeammates">
                Unloading teammates
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8">
              <Form.Control
                step={1}
                min={0}
                max={1000}
                type="number"
                value={settings.unloadingTeammates || countrySettings.unloading_teammates || ''}
                onChange={(event) => setSettings({ ...settings, unloadingTeammates: event.target.value || null })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="unloading_duration">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.unloadingDuration">
                Unloading duration
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8">
              <div className="duration-field">
                <DurationEdit
                  value={settings.unloadingDuration || countrySettings.unloading_duration}
                  onChange={(value) => setSettings({ ...settings, unloadingDuration: value || null })}
                  controlId="unloading_duration"
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="shelving_teammates">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.shelvingTeammates">
                Shelving teammates
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8">
              <Form.Control
                step={1}
                min={0}
                max={1000}
                type="number"
                value={settings.shelvingTeammates || countrySettings.shelving_teammates || ''}
                onChange={(event) => setSettings({ ...settings, shelvingTeammates: event.target.value || null })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="shelving_productivity">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.shelvingProductivity">
                Shelving productivity
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8">
              <Form.Control
                step={0.01}
                min={0}
                max={1000000}
                type="number"
                value={settings.shelvingProductivity || countrySettings.shelving_productivity || ''}
                onChange={(event) => setSettings({ ...settings, shelvingProductivity: event.target.value || null })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="efficiency-active">
            <Form.Label column lg="4" className="text-lg-right">
              <Trans i18nKey="efficiencyDeployment.settings.form.active">
                Active
              </Trans>
              {': '}
            </Form.Label>
            <Col lg="8" className="efficiency-active-switch">
              <SwitchFilter
                controlId="efficiency-isactive-switch"
                value={settings.storeEfficiencyActive || false}
                onChange={() => setSettings({ ...settings, storeEfficiencyActive: !settings.storeEfficiencyActive || null })}
              />
            </Col>
          </Form.Group>
          {!loading && error && <ErrorMessage error={error} />}
        </Form>
      )}
    </>
  );
};

export default SettingsForm;
